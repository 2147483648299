@use '@angular/material' as mat;
.panel {
  border: darkgray 1px solid;
  border-radius: 6px;
  height: 100%;
  display: grid;
  grid-template-rows: 26px auto;


  .title {
    box-sizing: border-box;
    background: mat.get-color-from-palette($primary, 100);
    background: linear-gradient(0deg, mat.get-color-from-palette($primary, 200) 0%, mat.get-color-from-palette($primary, 100) 25%);
    border-bottom: darkgray 1px solid;
    border-radius: 6px 6px 0 0;
    padding: 0 .6em;
    line-height: 1.8em;
    font-size: .8em;
    color: mat.get-contrast-color-from-palette($primary, 100);
    grid-row: 1;

    @include small_screen {
      font-size: 11px;
      padding: 3px 6px;
    }
  }

  .content {
    padding: .6em;
    overflow: auto;
    grid-row: 2;
  }
}

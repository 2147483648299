/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import "@angular/cdk/overlay-prebuilt.css";
@import url('https://fonts.googleapis.com/css?family=Roboto:400,700|Material+Icons');
@import url('https://fonts.googleapis.com/css?family=Fira+Mono&display=swap');
@import "variables";
@import "panels";
@import "icons";
@import "quill";

@include mat.all-component-themes($my-theme);
@include mat.core();

html, body {
  height: 100%;
  margin: 0;
}

.icon {
  height: 64px;
  width: 80px;
}

::ng-deep .svg-icon-fill {
  fill: #ffffff !important;
}

::ng-deep .svg-icon-border {
  fill: mat.get-color-from-palette($accent) !important;
}

.error-notification-overlay {
  color: $error-notification-text !important;
  background-color: $error-notification-colour !important;
}

.info-notification-overlay {
  color: $info-notification-text !important;
  background-color: $info-notification-colour !important;

  span {
    margin: auto !important;
    text-align: center !important;
  }
}

.success-notification-overlay {
  color: $success-notification-text;
  background-color: $success-notification-colour;
}

.warning-notification-overlay {
  color: #0F190F;
  background-color: $yellow;
}

.tooltip-panel {
  background-color: $very-light-grey;
  opacity: 1;
  border-radius: 4px;
  border: $light-grey 1px solid;
}

.mat-form-field.mat-focused {

  .mat-form-field-label {
    color: $form-field-colour !important;
  }

  .mat-form-field-underline {
    color: $form-field-colour !important;
  }

  .mat-form-field-ripple {
    color: $form-field-colour !important;
    background-color: $form-field-colour !important;
  }

  .mat-select-arrow {
    color: $form-field-colour !important;
  }

  .mat-form-field-required-marker {
    color: mat.get-color-from-palette($warn);
  }
}

.mat-select-panel {
  .mat-selected {
    color: $form-field-colour !important;
  }
}

.mat-ink-bar {
  background-color: $form-field-colour !important;
}

.mat-checkbox-ripple {
  .mat-ripple-element {
    background: $checkbox-checked-colour !important;
  }
}

.mat-checkbox-checked .mat-checkbox-background {
  background: $checkbox-checked-colour !important;
}

.mat-checkbox-disabled .mat-checkbox-background {
  background: grey !important;
}

input {
  caret-color: $form-field-colour !important;
}

textarea {
  caret-color: $form-field-colour !important;
}


.mat-raised-button {
  height: 32px;
  line-height: 30px !important;

  @include small_screen {
    height: 29px;
    line-height: 27px !important;
    font-size: 13px !important;
  }
}

.mat-raised-button:focus, .mat-button:focus, button:focus {
  outline: none;
}

.mat-raised-button.mat-primary {
  background-color: mat.get-color-from-palette($primary);

  &:hover {
    background-color: mat.get-color-from-palette($primary);
  }
}

.mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
  background-color: mat.get-color-from-palette($primary);
  color: #fff;
}

mat-dialog-actions {
  margin-top: 10px;
  border-top: 1px solid #dddddd;
}

.mat-tooltip {
  font-size: 12px;
}

h6 {
  position: relative;
  margin-left: 22px;
  font-weight: 600;

  @include small_screen {
    font-size: 16px;
    margin-left: 20px;
  }

  &::before {
    position: absolute;
    top: 1px;
    left: -20px;
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    background: linear-gradient(315deg, darken(mat.get-color-from-palette($accent), 10%) 0%, mat.get-color-from-palette($accent, 400) 100%);
    border: $mid-grey 1px solid;

    @include small_screen {
      width: 14px;
      height: 14px;
      left: -18px;
    }
  }
}

.ng-invalid {
  border: none;
}


.dialog-fieldset {
  border: 1px solid $light-grey;
  box-sizing: content-box;
  padding: 10px 20px 10px;
  border-radius: 10px;

  legend {
    font-size: 10pt;
    font-weight: 800;
    width: auto;
    padding: 0 10px;
    color: $light-grey;
  }
}


.button-with-image {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 22px;
    margin-right: 2px;
    margin-bottom: 1px;

    @include small_screen {
      height: 20px;
    }
  }
}


.button-icon {
  height: 24px;
}

.disable-button-icon {
  height: 21px;
}

.warning-dialog-backdrop {
  background-color: rgba(255, 0, 0, 0.25);
}

@include small_screen {
  body {
    font-size: 14px;
  }

  legend {
    font-size: 18px;
  }

  h4 {
    font-size: 18px;
  }

  .mat-button {
    font-size: 14px;
  }

  .mat-form-field-label-wrapper span, mat-label {
    font-size: 14px;
  }
}

.lead-colour {
  color: black;
}

.analyst-colour {
  color: mat.get-color-from-palette($primary);
}

.client-user-colour {
  color: limegreen;
}

.third-party-client-colour {
  color: black;
}

.ipUser-colour {
  color: mat.get-color-from-palette($accent);
}

.setTest {
  color: #007eff;
}

.field-header {
  color: mat.get-color-from-palette($primary);
  font-size: 75%;
}

.field-subject {
  border-bottom: 1px solid #e4e4e4;
}

.mat-tree-node {
  min-height: 20px !important;

  .mat-icon-button {
    height: 30px;
    line-height: 30px;
  }
}

.mat-raised-button.mat-accent {
  background-color: mat.get-color-from-palette($primary);
}

.valid-category-colour {
  color: limegreen;
}

.deprecated-category-colour {
  color: red;
}

:root {
  --table-alternate-row-colour: #{$table-even-row};
}

.custom-chip {
  max-width: 500px !important;
  white-space: nowrap;
  overflow: hidden;
  p {
    max-width: calc(91%);
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.authentication-panel {
  margin-top: 20vh !important;
}

/** small reusable class for any buttons that have an icon on the left **/
.button-has-icon {
  &:disabled {
    opacity: 0.6;
  }

  mat-icon:nth-of-type(1) {
    margin-left: -5px;
    margin-right: 5px;
  }
}

/**
 * Default styling for mat-stepper elements
 */
.mat-stepper-vertical,
.mat-stepper-horizontal {
  .mat-horizontal-content-container {
    padding-bottom: 0;
    padding-top: 6px; // Add for steppers that contain inputs at the top, the label text gets chopped off otherwise
  }
  
  .mat-vertical-content {
    padding-bottom: 0;
  }

  .mat-step-header {
    &.cdk-program-focused {
      background: none;
    }

    &:hover {
      &:not([aria-disabled=true]) {
        border-radius: 4px;

        &::after,
        &::before {
          opacity: 0;
        }
      }
    }
  }
}

@use '@angular/material' as mat;
@import '@angular/material/theming';

$md-primary: (
  50 : #ffede0,
  100 : #ffd5b9,
  200 : #ffb380,
  300 : #ff944d,
  400 : #ff7d26,
  500 : #ff6600,
  600 : #ff5e00,
  700 : #ff5300,
  800 : #ff4900,
  900 : #ff3800,
  A100 : #ffede0,
  A200 : #ffa366,
  A400 : #ff7d26,
  A700 : #ff6600,
  contrast: (
    50 : #000000,
    100 : #808080,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #ffffff,
  )
);

$md-accent: (
  50 : #e9fafb,
  100 : #d3f7f9,
  200 : #92c4c7,
  300 : #66acb1,
  400 : #459ba0,
  500 : #24898f,
  600 : #208187,
  700 : #1b767c,
  800 : #166c72,
  900 : #0d5960,
  A100 : #b3fbff,
  A200 : #62f0ff,
  A400 : #2fecff,
  A700 : #15e9ff,
  contrast: (
    50 : #6c6c6c,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$md-warn: (
  50 : #fee8e7,
  100 : #fcc7c3,
  200 : #faa19b,
  300 : #f77b72,
  400 : #f65f54,
  500 : #f44336,
  600 : #f33d30,
  700 : #f13429,
  800 : #ef2c22,
  900 : #ec1e16,
  A100 : #ffffff,
  A200 : #ffe9e9,
  A400 : #ffb8b6,
  A700 : #ff9f9c,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$primary: mat.define-palette($md-primary);
$accent:  mat.define-palette($md-accent);
$warn:    mat.define-palette($md-warn);

/* Overrides */

$home-icon-border-hover: black;
$sidebar-icon-border: white;
$sidebar-icon-fill: none;
$sidebar-icon-border-hover: black;
$sidebar-icon-fill-hover: none;
$sidebar-icon-border-active: black;
$sidebar-icon-fill-active: white;

$title-icon-border: #03a9b3;
  $title-gradient: linear-gradient(0deg, white 0%, mat.get-color-from-palette($accent, A100));
$faq-icon-fill: $title-icon-border;

$table-even-header: #d9eef0;
$table-odd-header: #d9eef0;
$table-even-row: mat.get-color-from-palette($accent, 100);
$table-hover: #eeeeee;

$overlay-btn-fill-hover: mat.get-color-from-palette($primary, 800);
$overlay-btn-border-hover: mat.get-contrast-color-from-palette($primary, 800);

$form-field-colour: #3f51b5;
$checkbox-checked-colour: #ff4081;
$success-notification-colour: #66bb69;
$info-notification-colour: #04a9f4;
$info-text-colour: white;
$username-colour: black;

:root {
  --accent-color: #{mat.get-color-from-palette($accent, 500)};
  --primary-color: #{mat.get-color-from-palette($primary, 500)};
  --warn-color: #{mat.get-color-from-palette($warn, 500)};
}

@use '@angular/material' as mat;
.mat-icon-inline {
  height: auto;
  vertical-align: middle;
}

/* Welcome page icons */
.nav-button {
  .icon {
    cursor: pointer;
    height: 60px;
    width: 60px;
  }

  .svg-icon-fill {
    fill: $home-icon-fill !important;
  }

  .svg-icon-border {
    fill: $home-icon-border !important;
  }

  &:hover {
    svg {
      filter: drop-shadow(0px 2px 3px $home-icon-border-hover) !important;
    }

    .svg-icon-border {
      fill: $home-icon-border-hover !important;
    }

    .svg-icon-fill {
      fill: $home-icon-fill-hover !important;
    }
  }
}

/* Icons by most titles. */
.title-text {
  svg {
    filter: drop-shadow(0px 0px 3px $title-icon-border) !important;
  }

  .icon {
    line-height: 1em;
    height: 60px;
  }

  .svg-icon-fill {
    fill: $title-icon-fill !important;
  }

  .svg-icon-border {
    fill: $title-icon-border !important;
  }
}

/* Sidebar icons */
.icon-container {
  .icon {
    cursor: pointer;
    height: 55px;
  }

  .svg-icon-fill {
    fill: $sidebar-icon-fill !important;
  }

  .svg-icon-border {
    fill: $sidebar-icon-border !important;

  }

  &:hover {
    .svg-icon-fill {
      fill: $sidebar-icon-fill-hover !important;
    }

    .svg-icon-border {
      fill: $sidebar-icon-border-hover !important;
    }
  }

  @include small_screen {
    .icon {
      cursor: pointer;
      height: 45px;
      width: 45px;
    }
  }
}

/* Sidebar icon active state */
.icon-container.active {
  svg {
    filter: drop-shadow(0px 2px 3px $sidebar-icon-border-active) !important;
  }

  .svg-icon-fill {
    fill: $sidebar-icon-fill-active !important;
  }

  .svg-icon-border {
    fill: $sidebar-icon-border-active !important;
  }
}

/* Icon on the "other" fullscreen pages. */
.title {
  svg {
    transform: translate(0, -14px);
    height: 150%;
    filter: drop-shadow(0px 2px 3px $title-icon-border) !important;
  }

  .icon {
    height: 60px !important;
    width: 120px !important;
  }

  .svg-icon-fill {
    fill: white !important;
  }

  .svg-icon-border {
    fill: $title-icon-border !important;
  }
}

/* Specifically for FAQ Icon as it is bigger than other icons.*/
.faq-title {
  svg {
    transform: translate(-10px, -45px);
    height: 245%;
    width: 120%;
    filter: drop-shadow(0px 3px 5px darken($title-icon-border, 15)) !important;
  }

  .icon {
    margin-right: 15px;
    height: 60px !important;
    width: 135px !important;
  }

  .svg-icon-fill {
    fill: white !important;
  }

  .svg-icon-border {
    fill: $title-icon-border !important;
  }
}

/* Overlay button theme*/
.overlay-svg {
  .icon {
    cursor: pointer;
    height: 32px;
    width: 32px;
  }

  .svg-icon-fill {
    fill: none !important;
  }

  .svg-icon-border {
    fill: mat.get-contrast-color-from-palette($accent, 200) !important;
  }

  &:hover {
    .svg-icon-fill {
      fill: $overlay-btn-fill-hover !important;
      stroke: black;
      stroke-width: 1pt;
    }

    .svg-icon-border {
      fill: $overlay-btn-border-hover !important;
    }
  }
}

/* Get Started Arrow on front page */
.startedIcon {
  svg {
    height: 180%;
    width: 180%;
  }

  .icon {
    display: inline;
    cursor: pointer;
    height: auto;
  }

  .svg-icon-fill {
    fill: mat.get-contrast-color-from-palette($primary, 500) !important;
  }

  .svg-icon-border {
    fill: mat.get-color-from-palette($primary) !important;
  }
}

/* FAQ Buttons */
.faq-body {
  svg{
    margin-top: 14px;
    height: 30px;
    width: 30px;
    filter: none !important;
  }
  .svg-icon-fill {
    fill: $faq-icon-fill !important;
  }

  .svg-icon-border {
    fill: $faq-icon-border !important;
  }
}

.faq-edit > .button-container {
  svg{
    margin-top: 14px;
    height: 30px;
    width: 30px;
    filter: none !important;
  }

  .deleteButton {
    cursor: pointer;

    .svg-icon-fill {
      fill: mat.get-color-from-palette($warn, 50);
    }

    .svg-icon-border {
      fill: mat.get-contrast-color-from-palette($warn, 50);
    }

    &:hover {
      .svg-icon-fill {
        fill: mat.get-color-from-palette($warn, 500);
      }

      .svg-icon-border {
        fill: mat.get-contrast-color-from-palette($warn, 500);
      }
    }
  }

  .moveButton {
    cursor: pointer;

    .svg-icon-fill {
      fill: mat.get-color-from-palette($primary, 50);
    }

    .svg-icon-border {
      fill: mat.get-contrast-color-from-palette($primary, 50);
    }

    &:hover {
      .svg-icon-fill {
        fill: mat.get-color-from-palette($primary, 500);
      }

      .svg-icon-border {
        fill: mat.get-contrast-color-from-palette($primary, 500);
      }
    }
  }
}

/* Overlay buttons in grid */
.row-col-svg {
  svg{
    margin-top: 10%;
    height: 80% !important;
    width: 24px;
  }
}

/* Default, fallback colours. Can be overwritten by respectable theme.scss files. */
@use '@angular/material' as mat;

$green: #28a745 !default;
$yellow: #ffd226;

$white: #ffffff;
$black: #000000;
$light-grey: #c8c8c8;
$mid-grey: #6c6c6c;
$dark-grey: #606060;
$very-light-grey: lighten($light-grey, 15);

$tooltip-green: limegreen;
$tooltip-red: red;
$tooltip-orange: orange;
$hold-button-yellow: #ffd226;
$tooltip-blue: #0000ff;
$tooltip-yellow: #eeee00;
$resume-button-green: #3ba922;

$not-started-test-colour: $mid-grey;
$in-progress-test-colour: $tooltip-orange;
$passed-test-colour: $tooltip-green;
$failed-test-colour: $tooltip-red;
$waived-test-colour: $tooltip-blue;
$overridden-test-colour: #c000c0;

$font-size-medium: medium;

$small-desktop-width: 1280px;

/* Import theme */
@import 'theme';

$my-theme: mat.define-light-theme($primary, $accent, $warn);

/* These values can be overwritten in theme.scss for respective clients */
$table-even-header: mat.get-color-from-palette($accent, 50) !default;
$table-odd-header: mat.get-color-from-palette($accent, 50) !default;
$table-even-column: mat.get-color-from-palette($accent, 50) !default;
$table-odd-row: white !default;
$table-even-row: mat.get-color-from-palette($accent, 50) !default;
$table-hover: mat.get-color-from-palette($primary, 50) !default;
$table-hover-selectable: mat.get-color-from-palette($primary, 100) !default;

$home-icon-border: $black !default;
$home-icon-fill: none !default;
$home-icon-border-hover: mat.get-color-from-palette($primary) !default;
$home-icon-fill-hover: $white !default;

$sidebar-icon-border: mat.get-contrast-color-from-palette($accent, 500) !default;
$sidebar-icon-fill: none !default;
$sidebar-icon-border-hover: mat.get-color-from-palette($primary) !default;
$sidebar-icon-fill-hover: mat.get-contrast-color-from-palette($primary, 500) !default;
$sidebar-icon-border-active: mat.get-color-from-palette($primary) !default;
$sidebar-icon-fill-active: mat.get-contrast-color-from-palette($primary, 500) !default;

$title-icon-border: mat.get-color-from-palette($accent) !default;
$title-icon-fill: $white !default;

$faq-icon-border: $light-grey !default;
$faq-icon-fill: mat.get-color-from-palette($accent) !default;

$overlay-btn-fill-hover: mat.get-contrast-color-from-palette($primary, 800) !default;
$overlay-btn-border-hover: mat.get-color-from-palette($primary, 800) !default;

$title-gradient: linear-gradient(0deg, $white 0%, mat.get-color-from-palette($primary, 50)) !default;

$username-colour: mat.get-contrast-color-from-palette($primary, 500) !default;

$success-notification-colour: mat.get-color-from-palette($accent, 600) !default;
$success-notification-text: mat.get-contrast-color-from-palette($accent, 600) !default;

$form-field-colour: mat.get-color-from-palette($primary) !default;
$checkbox-checked-colour: mat.get-color-from-palette($accent, A700) !default;
$info-colour: mat.get-color-from-palette($primary, A700) !default;
$snackbar-colour: mat.get-color-from-palette($primary, A700) !default;
$info-text-colour: white !default;
$username-colour: black !default;

$error-notification-text: mat.get-contrast-color-from-palette($warn, 500) !default;
$error-notification-colour: mat.get-color-from-palette($warn) !default;

$info-notification-text: mat.get-contrast-color-from-palette($accent, 600) !default;
$info-notification-colour: mat.get-color-from-palette($accent, 600) !default;

/* End of overwrite values */

@mixin small_screen {
  @media (max-width: $small-desktop-width) {
    @content;
  }
}
